import Image from 'next/image';
import Column from '@components/Column/Column';
import { Section, SectionContainer } from '@components/Section';
import Styles from './Styles.module.scss';

const talentTypeData = [
  {
    href: `/why`,
    imageTitle: `teams`,
    imageUrl: `/img/talent-types/multiple.svg`,
    title: `Multi-Talent, Managed Teams`,
    description: `On Demand Teams, Digital Transformation Experts and more. Need to assemble a team? Get in touch with a Hire Digital Teams specialist.`,
  },
  {
    href: `/marketing`,
    imageTitle: `Digial Marketing Experts`,
    imageUrl: `/img/talent-types/marketing.svg`,
    title: `Digital Marketing Experts`,
    description: `Social Media Marketers, SEO Experts, Digital PR Experts, PPC Experts, Content Strategists, Conversion Optimization Experts, Researchers and more.`,
  },
  {
    href: `/developers`,
    imageTitle: `developers`,
    imageUrl: `/img/talent-types/develop.svg`,
    title: `Developers`,
    description: `Front-end Developers, Tagging Developers, Creative Technologists, Back-end Developers, Data Analysts and more.`,
  },
  {
    href: `/designers`,
    imageTitle: `designers`,
    imageUrl: `/img/talent-types/design.svg`,
    title: `Designers`,
    description: `UI Designers, UX Researchers, Graphic Designers, Illustrators, Visual Designer, Interactive Designers, Art Directors and more.`,
  },
  {
    href: `/writers`,
    imageTitle: `writers`,
    imageUrl: `/img/talent-types/writer.svg`,
    title: `Writers`,
    description: `Website, Social Media, Email Newsletter, White Paper and Presentation Copywriters. Case Study, Thought Leadership Writers and more.`,
  },
  {
    href: `/marketing/project-manager`,
    imageTitle: `writers`,
    imageUrl: `/img/talent-types/manager.svg`,
    title: `Project Managers`,
    description: `Project Managers, Scrum Masters, Agile Managers, Kanban Managers, Product Managers, Technical Project Managers, and more.`,
  },
];

const TalentTypes = () => {
  return (
    <SectionContainer anchor='talent'>
      <Section
        title='Receive Curated Talent Recommendations'
        subtitle='We have helped our clients build teams for software development, digital marketing and design. With digital skills, domain expertise, and adaptability to succeed, our talents have improved ROI for organizations around the world.'>
        <Column.List isCardList>
          {talentTypeData.map((card, index) => {
            return (
              <Column.Card key={index} className={Styles.card} href={card.href}>
                <Column.Image type={Column.Image.Type.ICON}>
                  <Image
                    className={Styles.image}
                    height={75}
                    width={75}
                    title={card.imageTitle}
                    alt={card.imageTitle}
                    src={card.imageUrl}
                  />
                </Column.Image>
                <Column.Headline className={Styles.title}>{card.title}</Column.Headline>
                <Column.Content>{card.description}</Column.Content>
              </Column.Card>
            );
          })}
        </Column.List>
      </Section>
    </SectionContainer>
  );
};
export default TalentTypes;
